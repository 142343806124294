@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwindcss /ui;
@import url("https://fonts.googleapis.com/css2?family=DM+Serif+Display:ital@0;1&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap");

body {
  scroll-behavior: smooth !important;
}

/* :root {
  --amplify-primary-color: #c0412b;
  --amplify-primary-tint: #ff7359;
  --amplify-primary-shade: #e0573e;
} */

.home {
  min-height: 100vh;
}

.slick-prev:before,
.slick-next:before {
  color: rgb(12, 11, 11)!important;
}

.contain {
  height: 100vh;
}

label {
  @apply text-white;
}
/* .connect-body {
  background: #f1f3f2;
} */

.bubbles li {
  position: absolute;
  list-style: none;
  display: block;
  /* background-color: rgba(255, 255, 255, 0.15); */
  bottom: -100px;
  animation: circle 15s infinite;
  transition-timing-function: linear;
}

.bubbles li:nth-child(1) {
  width: 40px;
  height: 40px;
  left: 20%;
}

.bg-img {
  background-blend-mode: multiply;
  background-position: center center;
  background-size: cover;
  background-image: url("./images/two-tone-bg.jpg");
}

.bg-img-22 {
  background-blend-mode: multiply;
  background-position: center center;
  background-size: cover;
  background-image: url("./images/bg-22.png");
}
.bg-img-2 {
  background-blend-mode: multiply;
  background-position: center center;
  background-size: cover;
  background-image: url("./images/bg-2.png");
}

.bg-img-course-1 {
  background-blend-mode: multiply;
  background-position: center center;
  background-size: cover;
  background-image: url("./images/courses/card-1.png");
}

.bg-img-course-2 {
  background-blend-mode: multiply;
  background-position: center center;
  background-size: cover;
  background-image: url("./images/courses/card-2.png");
}

.bg-img-course-3 {
  background-blend-mode: multiply;
  background-position: center center;
  background-size: cover;
  background-image: url("./images/courses/card-3.png");
}

.bg-img-course-4 {
  background-blend-mode: multiply;
  background-position: center center;
  background-size: cover;
  background-image: url("./images/courses/card-4.png");
}

.bg-img-course-5 {
  background-blend-mode: multiply;
  background-position: center center;
  background-size: cover;
  background-image: url("./images/courses/card-5.png");
}

.bg-img-course-6 {
  background-blend-mode: multiply;
  background-position: center center;
  background-size: cover;
  background-image: url("./images/courses/card-6.png");
}

.bg-img-course-7 {
  background-blend-mode: multiply;
  background-position: center center;
  background-size: cover;
  background-image: url("./images/courses/card-7.png");
}

.bg-img-course-8 {
  background-blend-mode: multiply;
  background-position: center center;
  background-size: cover;
  background-image: url("./images/courses/card-8.png");
}

.bg-dark {
  background-color: #cfcfcf;
}

.text-dark {
  color: #cfcfcf;
}

.text-darker {
  color: #8a8a8a;
}

.bg-headers {
  background-color: #1b1b1b;
}

.border-darker {
  border: 2px solid #8a8a8a;
}

.new-shadow {
  box-shadow: 0 2px 4px 1px rgba(110, 110, 110, 0.6),
    0 2px 4px 2px rgba(110, 110, 110, 0.6);
}

.bubbles li:nth-child(2) {
  width: 60px;
  height: 60px;
  left: 40%;
  animation-delay: 2s;
  animation-duration: 16s;
}

.bubbles li:nth-child(3) {
  width: 30px;
  height: 30px;
  left: 60%;
  animation-delay: 4s;
  animation-duration: 13s;
}

.bubbles li:nth-child(4) {
  width: 80px;
  height: 80px;
  left: 80%;
  animation-delay: 1s;
  animation-duration: 19s;
}

.bubbles li:nth-child(5) {
  width: 50px;
  height: 50px;
  left: 50%;
  animation-delay: 5s;
  animation-duration: 12s;
}

.bubbles li:nth-child(6) {
  width: 25px;
  height: 25px;
  left: 10%;
  animation-delay: 3s;
  animation-duration: 11s;
}

.bubbles li:nth-child(7) {
  width: 55px;
  height: 55px;
  left: 70%;
  animation-delay: 2.5s;
  animation-duration: 11s;
}

.bubbles li:nth-child(8) {
  width: 75px;
  height: 75px;
  left: 30%;
  animation-delay: 3.5s;
  animation-duration: 11s;
}

@keyframes circle {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-1080px) rotate(800deg);
    transform: translateY(-1080px) rotate(800deg);
  }
}

.bg-brown-500 {
  background-color: #a3200f;
}

.bg-brown-400 {
  background-color: #b94b34;
}

.bg-brown-300 {
  background-color: #cc7059;
}

.bg-brown-200 {
  background-color: #dd9380;
}

.bg-brown-100 {
  background-color: #ebb6a9;
}

.text-brown-500 {
  color: #a3200f;
}

.text-brown-400 {
  color: #b94b34;
}

.text-brown-300 {
  color: #cc7059;
}

.text-brown-200 {
  color: #dd9380;
}

.text-brown-100 {
  color: #ebb6a9;
}
.nav a,
a {
  color: white;
}
.button-outline-none {
  outline: none !important;
}

#right-bar__about-us {
  background-image: url("./assets/right-bar.svg");
  background-position: top right;
  background-repeat: no-repeat;
}

#left-bar__about-us {
  background-image: url("./assets/left-bar.svg");
  background-position: top left;
  background-repeat: no-repeat;
}

@media screen and (min-width: 900px) {
  #home-header {
    background-image: url("./images/header-image.png");
    background-position: right;
    background-repeat: no-repeat;
  }
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

/* speak to clint page  */
.bg-img-1 {
  background-blend-mode: multiply;
  background-position: center center;
  background-size: cover;
  background-image: url("images/bg-img-1.png");
}

/* client speaks arrow  */
.client-btm-icon::after {
  content: url("images/icons/arrow.png");
  display: block;
  text-align: center;
}

/* The animation code */
@keyframes kids-animate {
  0% {
    left: -500px;
  }

  100% {
    left: 0px;
  }
}

/* The element to apply the animation to */
.images-animate {
  position: relative;
  animation-name: kids-animate;
  animation-duration: 2s;
  animation-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

@keyframes box-animate-left {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes box-animate {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.box-animate {
  animation: box-animate ease-out 2s forwards;
}
.box-animate-2 {
  animation: box-animate ease-out 2s 0.5s forwards;
}
.box-animate-3 {
  animation: box-animate ease-out 2s 1s forwards;
}

.box-animate-left {
  animation: box-animate-left ease-out 2s forwards;
}
.box-animate-left-2 {
  animation: box-animate-left ease-out 2s 0.5s forwards;
}
.box-animate-left-3 {
  animation: box-animate-left ease-out 2s 1s forwards;
}

#weekend-faq
  .ant-collapse-content.ant-collapse-content-active
  > .ant-collapse-content-box {
  background-color: #ffe8e8 !important;
}

#single-mentor-bio a {
  @apply font-extrabold text-blue-500 duration-200;
}

#single-mentor-bio a:hover {
  @apply text-blue-400;
}

#single-mentor-bio ul {
  @apply list-disc pl-8;
}

#single-mentor-bio ol {
  @apply list-decimal pl-8;
}

#single-course-desc a {
  @apply font-extrabold text-blue-500 duration-200;
}

#single-course-desc a:hover {
  @apply text-blue-400;
}

#single-course-desc ul {
  @apply list-disc pl-8;
}

#single-course-desc ol {
  @apply list-decimal pl-8;
}
